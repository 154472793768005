<template>
  <div class="merchanQLiFicaTionList MERCH">
    <div class="merch-card">
      <!-- tag标签 -->
      <div class="merch-tag">
        <el-tabs v-model="activeName" @tab-click="handleClick(activeName)">
          <el-tab-pane label="企业资质" name="0"> </el-tab-pane>
          <el-tab-pane label="资质库" name="1"></el-tab-pane>
        </el-tabs>
      </div>
      <template v-if="activeName == 0">
        <!-- 搜索列 -->
        <div class="merch-search">
          <div class="search-box">
            <div class="sch-1 sch-2">
              <el-input
                placeholder="请输入企业类型名称"
                v-model="EnterpriseApi.name"
                clearable
              ></el-input>
            </div>
            <div class="sch-1 sch-2">
              <el-button
                type="primary"
                size="mini"
                @click="searchEnterpriseTypelits"
              >
                查 询
              </el-button>
              <el-button type="primary" size="mini" @click="toAddqt">
                新建
              </el-button>
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <div
          :style="`height: ` + mainHright + `px`"
          class="merch-table scorr-roll"
        >
          <EnterpriseList />
        </div>
        <!-- 分页 -->
        <div class="merch-page">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="EnterpriseTyprList.per_page"
            layout="total, prev, pager, next, jumper"
            :total="EnterpriseTyprList.total"
            :current-page="EnterpriseApi.page"
          >
          </el-pagination>
        </div>
      </template>
      <template v-if="activeName == 1">
        <!-- 搜索列 -->
        <div class="merch-search">
          <div class="search-box">
            <div class="sch-1 sch-2">
              <!-- <div class="sch-title">资质状态:</div> -->
              <el-select
                v-model="dataBankListapi.enable_status"
                clearable
                placeholder="请选择资质状态"
              >
                <el-option
                  v-for="item in options"
                  :key="item.type"
                  :label="item.name"
                  :value="item.type"
                >
                </el-option>
              </el-select>
            </div>
            <div class="sch-1 sch-2">
              <!-- <div class="sch-title">证件类型:</div> -->
              <el-input
                clearable
                placeholder="请输入证件类型"
                v-model="dataBankListapi.qualification_name"
              ></el-input>
            </div>
            <div class="sch-1 sch-2">
              <el-button
                type="primary"
                size="mini"
                @click="searchQualificationlists"
              >
                查 询
              </el-button>
              <el-button
                type="primary"
                size="mini"
                @click="toAddzz"
                v-if="allJurisdiction.merchanQLiFicaTionList101"
              >
                新建
              </el-button>
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <div
          :style="`height: ` + mainHright + `px`"
          class="merch-table scorr-roll"
        >
          <DataBank :page="dataBankListapi.page" />
        </div>
        <!-- 分页 -->
        <div class="merch-page">
          <pagination
            v-show="setDataQa.total > 0"
            :total="setDataQa.total"
            :page.sync="dataBankListapi.page"
            :limit.sync="dataBankListapi.perPage"
            @pagination="getQualificationlists"
          />
        </div>
      </template>
    </div>
    <!-- 新建企业 -->
    <div class="dio-input">
      <el-dialog
        title="企业信息"
        :visible.sync="IsAddEnterprise"
        width="600px"
        @close="closeDialog"
      >
        <div>
          <el-form ref="form" label-width="150px">
            <el-form-item label="企业类型编号:">
              <el-input
                v-model="addCompanyType.company_type_code"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="企业类型名称:">
              <el-input
                v-model="addCompanyType.name"
                placeholder="企业类型名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="企业类别:">
              <el-radio-group v-model="addCompanyType.business_type">
                <el-radio :label="1">经营企业（批发）</el-radio>
                <el-radio :label="3">经营企业（连锁）</el-radio>
                <el-radio :label="2">生产企业</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="状态:">
              <el-switch
                v-model="addswitch"
                active-color="#13ce66"
                inactive-color="#ff4949"
                disabled
              >
              </el-switch>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="IsAddEnterprise = false">取 消</el-button>
          <el-button type="primary" @click="addqualificationType"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- 新建资质 -->
    <div class="dio-input">
      <el-dialog
        title="新建资质"
        :visible.sync="IsAddqualification"
        width="600px"
        @close="closeDialog"
      >
        <div>
          <el-form ref="form" label-width="150px">
            <el-form-item label="企业资质编号：">
              <el-input
                v-model="addQualification.qualification_type_code"
                placeholder="企业资质编号"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="企业资质名称：">
              <el-input
                v-model="addQualification.qualification_name"
                placeholder="企业资质名称："
              ></el-input>
            </el-form-item>
            <el-form-item label="示列图：">
              <div class="up-box">
                <div class="up-box-bas">
                  <el-upload
                    :action="Actions"
                    :class="{ hide: hideUpload }"
                    list-type="picture-card"
                    :limit="5"
                    :headers="token"
                    :file-list="imageList"
                    :data="{ type: '2' }"
                    :on-success="successUpImg"
                    :on-remove="removeImg"
                    :on-change="changeImg"
                    :before-upload="beforeAvatarUpload"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
            </el-form-item>

            <el-form-item label="排序：">
              <el-input
                v-model="addQualification.sequence"
                placeholder="正整数、越大越靠前"
                oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
              ></el-input>
            </el-form-item>
            <el-form-item label="关联经营类别">
              <el-select
                v-model="addQualification.business_scope"
                placeholder="请选择"
                multiple
                clearable
                style="width: 300px"
              >
                <el-option
                  v-for="item in businessCateList"
                  :key="item.id"
                  :label="item.type"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-select
                v-model="addQualification.required_type"
                placeholder="请选择"
                clearable
                style="width: 300px"
              >
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:">
              <el-switch
                v-model="addQualificationstatus"
                active-color="#06B7AE"
                inactive-color="#e6e8eb"
                :active-value="1"
                :inactive-value="0"
                disabled
              >
              </el-switch>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="IsAddqualification = false">取 消</el-button>
          <el-button type="primary" @click="addqualificationytyprlist"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { wholeUrl } from "@/api/phpUrl"; //引入接口
import compressImage from "@/utils/compressImage.js";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapActions, mapState } = createNamespacedHelpers("Basics"); //vuex公共库
import EnterpriseList from "./enterprise/enterpriseModule/enterpriseList"; //上架资质列表
import DataBank from "./enterprise/dataBankModule/dataBankList"; //资质库列表
export default {
  components: {
    EnterpriseList,
    DataBank,
  },
  computed: {
    ...mapState(["EnterpriseTyprList", "setDataQa"]),
    ...commonIndex.mapState(["mainHright", "allJurisdiction", "detailsSeach"]),
  },
  data() {
    return {
      searchData: {},
      activeName: 0,
      Actions: wholeUrl + "/basis/upload",
      token: { token: getToken() },
      imageList: [],
      IsAddEnterprise: false,
      IsAddqualification: false,
      form: {},
      hideUpload: false,
      value: "",
      addswitch: false,
      addQualificationstatus: 0,
      // 查询条件
      dataBankListapi: {
        page: 1,
        perPage: 10,
        qualification_name: "",
        enable_status: "",
      },
      options: [
        {
          name: "启用",
          type: 1,
        },
        {
          name: "停用",
          type: 0,
        },
      ],
      addCompanyType: {
        company_type_code: "",
        name: "",
        business_type: 1,
      },
      //新建企业类型的数据
      EnterpriseApi: {
        name: "",
        page: 1,
      },
      // 新建资质数据
      addQualification: {
        legend: [],
        business_scope: [],
        required_type: "", //是否必填
      },

      businessCateList: [], //经营类别数据
    };
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.activeName = this.detailsSeach[router].activeName;
      if (this.activeName == 0) {
        this.EnterpriseApi = this.detailsSeach[router];
        this.getEnterpriseTypelits();
      } else if (this.activeName == 1) {
        this.dataBankListapi = this.detailsSeach[router];
        this.getQualificationlists();
      }
    } else {
      this.getEnterpriseTypelits();
    }
    // this.getEnterpriseTypelits();
  },
  methods: {
    ...mapActions([
      "postaddbusinessQualification",
      "getBusinessQualificationTypeList",
      "getQualificationlist",
      "postaddQualification",
      "postconfigSet",
      "getaddGetLastCode",
      "getbusinessgetLastCode",
      "posttemplateedit",
      "getAllBusinessCategory", //经营类别
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    async beforeAvatarUpload(file) {
      if (file.size / 1024 / 1024 > 50)
        return this.$message.error("上传头像图片大小不能超过 50MB!");
      return await compressImage([file]);
    },
    // 删除
    removeImg() {
      this.imageList = [];
      this.hideUpload = this.imageList.length >= 5;
    },
    changeImg() {
      this.hideUpload = this.imageList.length >= 5;
    },
    successUpImg(data) {
      if (data.code == 200) {
        data.data.url = data.data.full_url;
        this.imageList.push(data.data);
        // this.addQualification.image = data.data.full_url;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 删除
    handleRemove() {},
    // 确认新建
    async addqualificationytyprlist() {
      this.addQualification.legend = [];
      this.imageList.forEach((v) => {
        this.addQualification.legend.push(v.full_url);
      });
      let falang = true;
      for (let key in this.addQualification) {
        if (this.addQualification[key] == "") {
          falang = false;
        }
      }
      if (!falang) {
        this.$message({
          message: "请将资料填写完整",
          type: "warning",
        });
        return;
      }
      let data = await this.postaddQualification(this.addQualification);
      if (data.code == 200) {
        this.imageList = [];
        (this.addQualification = {
          name: "",
          sort: "",
          status_: "1",
          image: "",
        }),
          (this.IsAddqualification = false);
        this.getQualificationlists();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 新建企业类型
    async addqualificationType() {
      if (!this.addCompanyType.name) {
        this.$message({
          message: "请填写企业类型名称",
          type: "warning",
        });
        return;
      }
      let data = await this.postaddbusinessQualification(this.addCompanyType);
      if (data.code == 200) {
        this.IsAddEnterprise = false;
        this.getEnterpriseTypelits();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 打开新建
    async toAddqt() {
      let data = await this.getaddGetLastCode();
      this.addCompanyType.company_type_code = data.data;
      this.IsAddEnterprise = true;
    },
    // 关闭新建
    closeDialog() {
      this.addCompanyType = {
        company_type_code: "",
        name: "",
        business_type: 1,
      };
      this.addQualification = {
        legend: [],
        sequence: "",
      };
    },
    async toAddzz() {
      this.imageList = [];
      let data = await this.getbusinessgetLastCode();
      this.addQualification.qualification_type_code = data.data;
      this.IsAddqualification = true;
      this.getAllBusineds();
    },
    // 获取资质库列表
    getQualificationlists() {
      this.getQualificationlist(this.dataBankListapi);

      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.dataBankListapi };
      save.activeName = this.activeName;
      save.router = router;
      this.changeSeach(save);
    },
    // 搜索资质库列表
    searchQualificationlists() {
      this.dataBankListapi.page = 1;
      this.getQualificationlists();
    },
    // 分页器获取资质库列表
    handleCurrentChange1(e) {
      this.dataBankListapi.page = e;
      this.getQualificationlists();
    },
    // 获取企业类型列表
    getEnterpriseTypelits() {
      this.getBusinessQualificationTypeList(this.EnterpriseApi);

      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.EnterpriseApi };
      save.activeName = this.activeName;
      save.router = router;
      this.changeSeach(save);
    },
    // 分页器获取企业资质列表
    handleCurrentChange(e) {
      this.EnterpriseApi.page = e;
      this.getEnterpriseTypelits();
    },
    // 搜索企业资质列表
    searchEnterpriseTypelits() {
      this.EnterpriseApi.page = 1;
      this.getEnterpriseTypelits();
    },
    // tag标签切换
    handleClick(tab, event) {
      if (tab == 1) {
        this.searchQualificationlists();
      } else {
        this.searchEnterpriseTypelits();
      }
    },
    /** 获取经营类别 */
    getAllBusineds() {
      this.getAllBusinessCategory().then((res) => {
        this.businessCateList = res.content;
      });
    },
  },
};
</script>
<style style lang="scss">
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
.up-box-bas .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;

  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .TRAN-BOX {
      width: 100%;
      height: 600px;
    }
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      height: 50px;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 60%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      // max-height: 60vh;
      // height: 200px;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
::v-deep .hide .el-upload--picture-card {
  display: none;
}
</style>
