<template>
  <div>
    <div>
      <el-table :data="EnterpriseTyprList.data" border style="width: 100%">
        <el-table-column prop="company_type_code" label="编号" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              effect="dark"
              :content="row.company_type_code"
              placement="bottom"
            >
              <div class="exceed">
                {{ row.company_type_code ? row.company_type_code : "/" }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="企业类型名称" align="center">
          <template slot-scope="{ row }">
            <el-tooltip effect="dark" :content="row.name" placement="bottom">
              <div class="exceed">
                {{ row.name ? row.name : "/" }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="状态" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.enable_status != undefined">
              {{ row.enable_status == 1 ? "启用" : "停用" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="unity_count"
          label="三证合一资质数"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-tooltip placement="top">
              <div slot="content">
                <div v-for="(item, index) in row.unity" :key="index">
                  {{ item.qualification_name }}
                </div>
              </div>
              <div>{{ row.unity_count }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="no_unity_count"
          label="非三证合一资质数"
          align="center"
        >
          <template slot-scope="{ row }">
            <div class="nobor">
              <el-tooltip placement="top">
                <div slot="content">
                  <div v-for="(item, index) in row.no_unity" :key="index">
                    {{ item.qualification_name }}
                  </div>
                </div>
                <div>{{ row.no_unity_count }}</div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center" width="300">
          <template slot-scope="{ row }">
            <div class="userBtn">
              <!-- <div v-if="row.STATUS_ == 1" @click="toAccount(row, 1)">停用</div> -->
              <el-popconfirm
                @confirm="toAccount(row, 1)"
                title="确定停用当前企业类型？停用后新入驻商家将无法选择。"
                v-if="
                  row.enable_status == 1 &&
                  allJurisdiction.merchanQLiFicaTionList98
                "
              >
                <div slot="reference">停用</div>
              </el-popconfirm>
              <el-popconfirm
                @confirm="toAccount(row, 5)"
                title="确定启用当前企业类型？"
                v-if="
                  row.enable_status != 1 &&
                  allJurisdiction.merchanQLiFicaTionList98
                "
              >
                <div slot="reference">启用</div>
              </el-popconfirm>
              <!-- <div v-else @click="toAccount(row, 5)">启用</div> -->
              <div
                @click="toAccount(row, 2)"
                v-if="allJurisdiction.merchanQLiFicaTionList99"
              >
                编辑
              </div>
              <div
                @click="toAccount(row, 3)"
                v-if="allJurisdiction.merchanQLiFicaTionList100"
              >
                三证合一
              </div>
              <div
                @click="toAccount(row, 4)"
                v-if="allJurisdiction.merchanQLiFicaTionList100"
              >
                非三证合一
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <!-- 编辑企业 -->
      <div class="dio-input">
        <el-dialog
          title="企业信息"
          :visible.sync="IsAddEnterprise"
          width="600px"
        >
          <div>
            <el-form ref="form" label-width="150px">
              <el-form-item label="企业类型编号:">
                <el-input
                  v-model="addCompanyType.company_type_code"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="企业类型名称:">
                <el-input
                  v-model="addCompanyType.name"
                  placeholder="企业类型名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业类别:">
                <el-radio-group v-model="addCompanyType.business_type">
                  <el-radio :label="1">经营企业（批发）</el-radio>
                  <el-radio :label="3">经营企业（连锁）</el-radio>
                  <el-radio :label="2">生产企业</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="状态:">
                <el-switch
                  v-model="addCompanyType.enable_status"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="IsAddEnterprise = false">取 消</el-button>
            <el-button type="primary" @click="addqualificationType"
              >确 定</el-button
            >
          </span>
        </el-dialog>
      </div>
    </div>
    <!-- 配置资质 -->
    <div>
      <el-dialog :title="isthree" :visible.sync="isdeploy" width="800px">
        <div>
          <!-- 搜索列 -->
          <div class="dialog-head">
            <div>企业类型编号：{{ deployMessage.company_type_code }}</div>
            <div>企业类型名称：{{ deployMessage.business_type_name }}</div>
            <div>状态：{{ deployMessage.enable_status_name }}</div>
          </div>
          <div class="required-qualifications">
            <div class="choseform-name">入驻所需资质:</div>
            <div class="choseform">
              <div class="choseform-head">
                资质库
                <span
                  >已选:{{ qualification_code.length }}/{{
                    atDeplyData.length
                  }}</span
                >
              </div>
              <div class="choseform-form">
                <div class="choseform-Checkbox">
                  <div v-for="(v, i) in atDeplyData" :key="i">
                    <el-checkbox
                      v-model="v.Checkbox"
                      @change="changeCheckbox"
                      >{{ v.qualification_name }}</el-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isdeploy = false">取 消</el-button>
          <el-button type="primary" @click="sureSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions, mapMutations } =
  createNamespacedHelpers("Basics"); //vuex公共库
export default {
  data() {
    return {
      IsAddEnterprise: false,
      addCompanyType: {},
      isdeploy: false,
      deployMessage: {},
      qualification_code: [],
      deplyData: {},
      atDeplyData: {},
      isthree: "三证合一入驻需要资质",
      unity_type: 0,
    };
  },
  computed: {
    ...mapState(["EnterpriseTyprList"]),
    ...commonIndex.mapState(["allJurisdiction"]),
  },
  methods: {
    ...mapActions([
      "getbusinessQualificationTypeedit",
      "postbusinessQualificationTypeedit",
      "getBusinessQualificationTypeList",
      "gettemplateList",
      "getbusinessqualificationGrouplist",
      "postbusinessqualificationGroupset",
    ]),
    ...mapMutations(["cgdisplay"]),
    mouseOver(val, event) {
      let params = {
        row: val,
        type: event,
        show: true,
      };
      this.cgdisplay(params);
    },
    mouseLeave(val, event) {
      let params = {
        row: val,
        type: event,
        show: false,
      };
      this.cgdisplay(params);
      // console.log(val);
    },
    // 操作
    async toAccount(row, code) {
      switch (code) {
        case 1:
          let NEWdata1 = await this.postbusinessQualificationTypeedit({
            id: row.id,
            enable_status: 0,
          });
          if (NEWdata1.code == 200) {
            this.$parent.getEnterpriseTypelits();
            this.$message({
              message: NEWdata1.message,
              type: "success",
            });
          } else {
            this.$message({
              message: NEWdata1.message,
              type: "warning",
            });
          }
          break;
        case 2:
          let data = await this.getbusinessQualificationTypeedit({
            id: row.id,
          });
          if (data.code == 200) {
            this.addCompanyType = data.data;
            if (this.addCompanyType.enable_status == 1) {
              this.addCompanyType.enable_status = true;
            } else {
              this.addCompanyType.enable_status = false;
            }
            this.IsAddEnterprise = true;
          } else {
            this.$message({
              message: data.message,
              type: "warning",
            });
          }
          break;
        case 3:
          this.threeApi(row.company_type_code, 1);
          this.deployMessage = row;
          this.unity_type = 1;
          this.isdeploy = true;
          this.isthree = "三证合一入驻需要资质";

          // this.$router.push({
          //   path: `/checkQuaList`,
          //   query: {
          //     type_id: row.ID,
          //     is_unity: 1,
          //   },
          // });
          break;
        case 4:
          this.threeApi(row.company_type_code, 0);
          this.deployMessage = row;
          this.isdeploy = true;
          this.unity_type = 0;
          this.isthree = "非三证合一入驻需要资质";
          // this.$router.push({
          //   path: `/checkQuaList`,
          //   query: {
          //     type_id: row.ID,
          //     is_unity: 0,
          //   },
          // });
          break;
        case 5:
          let NEWdata2 = await this.postbusinessQualificationTypeedit({
            id: row.id,
            enable_status: 1,
          });
          if (NEWdata2.code == 200) {
            this.$parent.getEnterpriseTypelits();
            this.$message({
              message: NEWdata2.message,
              type: "success",
            });
          } else {
            this.$message({
              message: NEWdata2.message,
              type: "warning",
            });
          }
          break;
      }
    },
    // 选中状态
    changeCheckbox() {
      let list = this.deplyData.filter((val) => {
        return val.Checkbox;
      });
      this.qualification_code = [];
      list.forEach((v) => {
        if (v.Checkbox) {
          this.qualification_code.push(v.qualification_type_code);
        }
      });
    },
    // 配置三证api
    async threeApi(val, evnet) {
      let params = {
        type_code: val,
        unity_type: evnet,
      };
      let data = await this.getbusinessqualificationGrouplist(params);
      if (data.code == 200) {
        data.data.forEach((val) => {
          if (val.id == null) {
            val.Checkbox = false;
          } else {
            val.Checkbox = true;
          }
        });
        let list = data.data.filter((v) => {
          return v.Checkbox;
        });
        this.qualification_code = list;
        this.deplyData = data.data;
        this.atDeplyData = this.deplyData;
        this.isdeploy = true;
        this.changeCheckbox();
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 确认编辑
    async addqualificationType() {
      let data = await this.postbusinessQualificationTypeedit(
        this.addCompanyType
      );
      if (data.code == 200) {
        this.$message({
          message: data.message,
          type: "success",
        });
        this.$parent.getEnterpriseTypelits();
        this.IsAddEnterprise = false;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 确定
    async sureSubmit() {
      if (this.qualification_code.length == 0) {
        this.$message({
          message: "请至少选择一项",
          type: "warning",
        });
        return false;
      }
      let params = {
        user_type: this.deployMessage.business_type_name,
        user_code: this.deployMessage.company_type_code,
        unity_type: this.unity_type,
        qualification_code: this.qualification_code,
      };
      let res = await this.postbusinessqualificationGroupset(params);
      if (res.code == "200") {
        this.$parent.getEnterpriseTypelits();
        this.isdeploy = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.dialog-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.required-qualifications {
  display: flex;
}
.choseform-name {
  line-height: 34px;
}
.choseform {
  flex: 1;
  border: 1px solid #ccc;
  margin-left: 20px;

  .choseform-form {
    padding: 10px;
  }
  .choseform-input {
    width: 200px;
  }
  .choseform-Checkbox {
    overflow-y: scroll;
    height: 200px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    margin-top: 10px;
    > div {
      height: 24px;
      line-height: 24px;
    }
  }
}
.choseform-head {
  display: flex;
  flex: 1;
  justify-content: space-between;
  background-color: #ccc;
  padding: 10px;
}
::v-deep .el-checkbox__label {
  color: #909399;
}
::v-deep .el-table .cell {
  overflow: visible !important;
}
.nobor {
  position: relative;
  .immigration {
    position: absolute;

    z-index: 99;
    left: 50%;
    width: 200px;
    color: #fff;
    // height: 100px;
    margin-left: -100px;
    background-color: #909399;
  }
  .immigration0 {
    top: 25px;
  }
  .immigration1 {
    bottom: 25px;
  }

  .immigration2 {
    top: 25px;
  }
  .immigration3 {
    bottom: 25px;
  }
}
</style>
