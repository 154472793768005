import { render, staticRenderFns } from "./dataBankList.vue?vue&type=template&id=35e84071&scoped=true&"
import script from "./dataBankList.vue?vue&type=script&lang=js&"
export * from "./dataBankList.vue?vue&type=script&lang=js&"
import style0 from "./dataBankList.vue?vue&type=style&index=0&id=35e84071&lang=scss&scoped=true&"
import style1 from "./dataBankList.vue?vue&type=style&index=1&id=35e84071&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35e84071",
  null
  
)

export default component.exports