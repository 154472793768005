<template>
  <div>
    <div>
      <el-table :data="setDataQa.data" border style="width: 100%">
        <el-table-column
          prop="qualification_type_code"
          label="编号"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="qualification_name"
          label="证件类型"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="address" label="示例图" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.legend">
              <el-image
                style="width: 40px; height: 40px"
                :src="row.legend[0]"
                :preview-src-list="[row.legend[0]]"
              >
              </el-image>
            </div>
            <div v-else>
              <div>/</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="状态" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.enable_status == 1 ? "启用" : "停用" }}</div>
          </template>
        </el-table-column>
        <!--  -->
        <el-table-column prop="sequence" label="排序" align="center">
          <template slot-scope="{ row }">
            <div>
              {{ row.sequence }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center" width="250">
          <template slot-scope="{ row }">
            <div class="userBtn">
              <!--  -->
              <!-- <div @click="toAccount(row)">停用</div> -->
              <div
                @click="deploy(row)"
                v-if="allJurisdiction.merchanQLiFicaTionList103"
              >
                配置
              </div>
              <div
                @click="redact(row)"
                v-if="allJurisdiction.merchanQLiFicaTionList102"
              >
                编辑
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 配置资质 -->
    <div>
      <el-dialog
        title="配置企业资质所需字段"
        :visible.sync="isdeploy"
        width="1100px"
      >
        <div>
          <!-- <div class="type-title">
            证件类型<span>{{ pzTitle.name }}</span>
          </div> -->
          <!-- <div class="type-title">录入项</div> -->
          <div class="box-1 scorr-roll">
            <div
              class="entering-box"
              v-for="(item, index) in deplyValue"
              :key="index"
            >
              <div class="entering">
                <div class="input-1">
                  <el-input
                    v-model="item.sequence"
                    placeholder="排序"
                  ></el-input>
                </div>
                <div class="input-1">
                  <el-input
                    v-model="item.item_name"
                    placeholder="显示标题"
                  ></el-input>
                </div>
                <div class="select-box">
                  <el-select
                    style="width: 150px"
                    v-model="item.item_type"
                    placeholder="录入类型"
                  >
                    <el-option
                      v-for="etem in TypeOptions"
                      :key="etem.type"
                      :label="etem.name"
                      :value="etem.type"
                    >
                    </el-option>
                  </el-select>
                </div>
                <el-upload
                  v-if="item.item_type == 3"
                  class="upload-demo"
                  :action="Actions"
                  :headers="{ token: token }"
                  :data="{ type: '6' }"
                  :on-exceed="exceedWj"
                  :on-remove="
                    (data) => {
                      return handleRemoveUpImg(data, index);
                    }
                  "
                  :before-remove="
                    (data) => {
                      return beforeRemoveUpImg(data, index);
                    }
                  "
                  :on-success="
                    (data) => {
                      return successUpImg(data, index);
                    }
                  "
                  :file-list="item.fileListData"
                  :multiple="false"
                  :limit="5"
                >
                  <el-button size="small" type="primary" class="upmarginright"
                    >上传模板</el-button
                  >
                </el-upload>
                <div class="checkbox-must">
                  必填
                  <el-switch
                    v-model="item.required_type"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  >
                  </el-switch>
                </div>
              </div>
              <div class="add-nuis">
                <!-- <span class="add" @click="additem" v-if="index==deplyValue.length-1">添加</span> -->
                <span class="minus" @click="deltelDep(index)" v-if="index > 2"
                  >删除</span
                >
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isdeploy = false">取 消</el-button>
          <el-button @click="additem">添加</el-button>
          <el-button type="primary" @click="affirmAdd">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 新建资质 -->
    <div class="dio-input">
      <el-dialog
        title="编辑资质"
        :visible.sync="IsAddqualification"
        width="600px"
      >
        <div>
          <el-form ref="form" label-width="150px">
            <el-form-item label="企业资质编号：">
              <el-input
                v-model="addQualification.qualification_type_code"
                placeholder="企业资质编号："
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="企业资质名称：">
              <el-input
                v-model="addQualification.qualification_name"
                placeholder="企业资质名称："
              ></el-input>
            </el-form-item>
            <el-form-item label="示列图：">
              <div class="up-box">
                <div class="rightUp">
                  <el-upload
                    ref="upload1"
                    :action="Actions"
                    :class="{ hide: hideUpload }"
                    :headers="{ token: token }"
                    :data="{ type: 2 }"
                    :limit="5"
                    :file-list="imageList"
                    list-type="picture-card"
                    :on-success="onSuccess"
                    :on-remove="onRemove"
                    :on-change="changeImg"
                    :before-upload="beforeUploadFunction"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="排序：">
              <el-input
                v-model="addQualification.sequence"
                placeholder="正整数、越大越靠前"
                oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
              ></el-input>
            </el-form-item>
            <el-form-item label="关联经营类别">
              <el-select
                v-model="addQualification.business_scope"
                placeholder="请选择"
                multiple
                clearable
                style="width: 300px"
              >
                <el-option
                  v-for="item in businessCateList"
                  :key="item.id"
                  :label="item.type"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-select
                v-model="addQualification.required_type"
                placeholder="请选择"
                clearable
                style="width: 300px"
              >
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:">
              <el-switch
                v-model="addQualification.enable_status"
                active-color="#06B7AE"
                inactive-color="#e6e8eb"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="IsAddqualification = false">取 消</el-button>
          <el-button type="primary" @click="addqualificationytyprlist"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { wholeUrl } from "@/api/phpUrl"; //引入接口
import compressImage from "@/utils/compressImage.js";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("Basics"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
import { imgUploadUrl } from "@/api/phpUrl.js";
export default {
  data() {
    return {
      Actions: wholeUrl + "/basis/upload",
      TypeOptions: [
        {
          type: 1,
          name: "文本",
        },
        {
          type: 2,
          name: "日期",
        },
        {
          type: 3,
          name: "图片",
        },
        // {
        //   type: 4,
        //   name: "文件",
        // },
      ],
      // 类型
      pzTitle: {},
      hideUpload: false,
      deplyValue: [
        {
          is_must: "0",
        },
      ], //资质配置项数据
      checked: false, //切换选中
      isdeploy: false,
      IsAddqualification: false,
      addQualification: {
        legend: [],
        business_scope: [],
        required_type: "", //是否必填
        qualification_name: "", //企业资质名称
        qualification_type_code: "", //企业资质编号
      },
      businessCateList: [], //经营类别数据
      imgUploadUrl: "",
      imageList: [],
      token: getToken(),
      fileList: "",
      fileListData: [],
    };
  },
  props: {
    page: {
      type: Number,
      default: function () {
        return;
      },
    },
  },

  mounted() {
    this.imgUploadUrl = imgUploadUrl;
  },
  computed: {
    ...mapState(["setDataQa"]),
    ...commonIndex.mapState(["allJurisdiction"]),
  },
  methods: {
    ...mapActions([
      "getQualificationlist",
      "postconfigSet",
      "getconfigList",
      "posttemplateedit",
      "gettemplateedit",
      "getAllBusinessCategory", //经营类别
    ]),
    handleRemoveUpImg(val, index) {
      this.deplyValue[index].legend.forEach((v, i) => {
        if (v == val.url) {
          this.deplyValue[index].legend.splice(i, 1);
        }
      });
    },
    beforeRemoveUpImg(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    async beforeUploadFunction(file) {
      const _name = file.name;
      const _index = _name.lastIndexOf(".");
      const _imgType = _name.substring(_index);
      if (
        _imgType == ".jpg" ||
        _imgType == ".jpeg" ||
        _imgType == ".png" ||
        _imgType == ".PNG" ||
        _imgType == ".gif" ||
        _imgType == ".bmp" ||
        _imgType == ".BMP" ||
        _imgType == ".GIF"
      ) {
      } else {
        this.$message.error(`图片格式仅支持.png、.jpg、.jpeg、.bmp、.gif!`);
        return false;
      }
      if (file.size / 1024 / 1024 > 50)
        return this.$message.error("上传头像图片大小不能超过 50MB!");
      return await compressImage([file]);
    },
    //取消单选
    selectRa(val, i) {
      val == 0
        ? (this.deplyValue[i].is_must = 1)
        : (this.deplyValue[i].is_must = 0);
    },
    // 确认添加配置项
    async affirmAdd() {
      let data = await this.postconfigSet(this.deplyValue);
      if (data.code == 200) {
        this.isdeploy = false;
        this.$parent.searchQualificationlists();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 添加配置项
    additem() {
      this.deplyValue.push({
        template_id: this.pzTitle.id,
        is_must: "0",
        required_type: false,
      });
    },
    //
    successUpImg(data, val) {
      if (data.code == 200) {
        this.deplyValue[val].legend.push(data.data.full_url);
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 超出
    exceedWj(data) {
      this.$message({
        message: "最多上传五个文件",
        type: "warning",
      });
    },
    // 上传图片成功
    onSuccess(e) {
      if (e.code == 200) {
        this.imageList.push({ url: e.data.full_url });
        this.fileList = e.data.full_url;
      }
    },
    onRemove(data) {
      this.imageList = [];
      this.fileList = "";
      this.hideUpload = this.imageList.length >= 5;
    },
    changeImg() {
      this.hideUpload = this.imageList.length >= 5;
    },
    // 删除配置项
    deltelDep(i) {
      this.deplyValue.splice(i, 1);
    },
    // 切换选中
    toAccount() {},
    // 配置
    async deploy(val) {
      this.pzTitle = val;
      let data = await this.getconfigList({ template_id: val.id });
      if (data.code == 200) {
        if (data.data.length > 0) {
          this.deplyValue = data.data;
          this.deplyValue.forEach((item) => {
            if (item.legend.length > 0) {
              this.$set(item, "fileListData", []);
              item.legend.forEach((v) => {
                item.fileListData.push({ url: v, name: "示例图片" });
              });
            } else {
              item.legend = [];
            }

            if (item.required_type == 1) {
              item.required_type = true;
            } else {
              item.required_type = false;
            }
          });
        } else {
          this.deplyValue = [
            {
              template_id: this.pzTitle.id,
              is_must: "0",
            },
          ];
        }
        this.isdeploy = true;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 编辑自制库
    async redact(row) {
      this.getAllBusineds();
      let data = await this.gettemplateedit({ id: row.id });
      if (data.code == 200) {
        this.imageList = [];
        if (data.data.legend.length > 0) {
          data.data.legend.forEach((v) => {
            this.imageList.push({ url: v });
          });
          this.hideUpload = true;
        } else {
          this.imageList = [];
          this.hideUpload = false;
        }
        this.addQualification = data.data;
        this.IsAddqualification = true;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    /** 获取经营类别 */
    getAllBusineds() {
      this.getAllBusinessCategory().then((res) => {
        this.businessCateList = res.content;
      });
    },
    // 编辑资质
    async addqualificationytyprlist() {
      this.addQualification.legend = [];
      this.imageList.forEach((v) => {
        this.addQualification.legend.push(v.url);
      });
      // delete this.addQualification.img_number;
      // delete this.addQualification.required_type;
      // delete this.addQualification.group_type;
      // delete this.addQualification.checked;
      let data = await this.posttemplateedit(this.addQualification);
      if (data.code == 200) {
        this.hideUpload = false;
        this.getQualificationlist({ page: this.page });
        this.IsAddqualification = false;
        // 编辑完成 清除保存的图片信息
        this.fileList = "";
        // 编辑完成 清除组件保存的图片信息
        this.$refs.upload1.clearFiles();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
.checkbox-must .el-checkbox__label {
  color: #494c57;
}
.checkbox-must {
  margin-left: 20px;
}
.upmarginright {
  margin-left: 20px;
}
.input-1 .el-input {
  border-radius: 4px;
}
</style>
<style lang="scss" scoped>
.up-box {
  display: flex;
  .leftUp {
    margin-right: 20px;
  }
}
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.type-title {
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  span {
    margin-left: 10px;
    font-size: 17px;
    font-weight: 600;
  }
}
.box-1 {
  max-height: 300px;
}
.entering-box {
  margin-top: 10px;
  width: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
  border-bottom: 1px #ccc dashed;
  .entering {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    // border: 1px solid #cdd0d6;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding: 5px 10px;
    .input-1 {
      width: 150px;
      border-radius: 4px;
      margin-right: 20px;
    }
  }
  .add-nuis {
    width: 15%;
    padding: 0px 5px;
    display: flex;
    justify-content: space-between;
    .add {
      color: #1abca9;
      cursor: pointer;
    }
    .minus {
      color: #1abca9;
      cursor: pointer;
    }
  }
}
::v-deep .hide .el-upload--picture-card {
  display: none;
}
</style>
